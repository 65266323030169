.global .streaming-container > iframe,
.global iframe {
  width: 100%;
}

.global .shands-iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #d3d3d3;
  padding-bottom: 56.25%;
}

.global .shands-iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.global .shands-post-cta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.global .shands-post-cta-caption {
  color: #545454;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
}

.global .shands-post-cta-link {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  color: white;
  border-radius: 9999px;
  padding: 1rem 2rem;
}

.global .shands-post-cta-play {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0.5rem;
}
